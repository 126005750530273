import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';

import Markdown from 'components/common/markdown/Markdown';
import Namecheck from 'components/directus/namecheck/Namecheck';
import Video from 'components/common/video/Video';
import heroHook from 'hooks/hero/hero.hook';
import { useWindowWidth } from 'hooks/window-width';
import { StoreProps } from 'store/reducers/namecheck/namecheck.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import getBrowserName from 'utils/get-browser-name';
import { Props } from './HeroNamecheck.interface';

import {
  heroNamecheck__hero___hidden,
  heroNamecheck,
  heroNamecheck__hero,
  heroNamecheck__heading,
  heroNamecheck__subheading,
  illustration,
} from './HeroNamecheck.scss';

const HeroNameCheck = ({ slug, state, dispatch }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, heroHook());
  const [isSafari, setIsSafari] = useState('');
  const isMobile = useWindowWidth() <= 1200;
  const { visibility } = state;
  const heroStyle = !visibility ? heroNamecheck__hero___hidden : '';

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setIsSafari(getBrowserName(navigator.userAgent));
    }
  });

  return (
    <div className={heroNamecheck}>
      <Grid>
        <Row>
          <Col xs={12} xl={7}>
            <div className={`${heroNamecheck__hero} ${heroStyle}`}>
              <h1 className={heroNamecheck__heading}>
                <Markdown source={node.heading} />
              </h1>
              <h3 className={heroNamecheck__subheading}>
                <Markdown source={node.subheading} />
              </h3>
            </div>
            <Namecheck
              home
              handleChange={(visibility: boolean) => dispatch({ type: 'UPDATE_NAMECHECKVISIBLE', payload: visibility })}
            />
          </Col>
          <Col xs={12} xl={5}>
            <div className={illustration}>
              {!isMobile && (
                <Video
                  videoSrcURL={
                    isSafari === 'Apple Safari' ? node.model_image_safari.data.full_url : node.model_image.data.full_url
                  }
                  type={isSafari ? 'safari' : ''}
                />
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default connect((state: StoreProps) => ({
  state: { ...state.nameCheck },
}))(HeroNameCheck);
