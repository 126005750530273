import React from 'react';
import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { component__link } from 'components/directus/customer-reviews/CustomerReviews.scss';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

export default () => {
  const { node: section } = getFieldBySlug('recent-reviews', sectionTitleHook());

  return (
    <>
      <SectionTitle title={section.heading} subtitle={section.subheading} align="center" />

      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="51d2b23c0000640005506d5b"
        data-style-height="240px"
        data-style-width="100%"
        data-stars="5"
        data-review-languages="en"
      >
        <a href="https://uk.trustpilot.com/review/rapidformations.co.uk" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>

      <ButtonGroup block>
        <Button role="secondary" size="large" to="/customer-reviews/" className={component__link}>
          Our Customer Reviews
        </Button>
      </ButtonGroup>
    </>
  );
};
