import React from 'react';

import { Props } from './Video.interface';

import { video } from './Video.scss';

export default ({ videoSrcURL, type }: Props) => (
  /* eslint-disable jsx-a11y/media-has-caption */
  /* autoplay requires audio, muted requires captions jsx-a11y rule, but no captions */
  <video loop autoPlay muted className={video}>
    <source src={videoSrcURL} type={type === 'safari' ? 'video/mp4' : 'video/webm'} />
  </video>
);
